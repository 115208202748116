import React from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { useLoader } from '@react-three/fiber';
import { STLLoader } from 'three-stdlib';

import { MeshStandardMaterial } from 'three';

function Model({ url }) {
  const geometry = useLoader(STLLoader, url);
  return (
    <mesh geometry={geometry}>
      <primitive attach="material" object={new MeshStandardMaterial({ color: 'orange' })} />
    </mesh>
  );
}

function STLViewer({ url }) {
  return (
    <Canvas>
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      <Model url={url} />
      <OrbitControls />
    </Canvas>
  );
}

function StlView() {
  return <STLViewer url="/models/frame_ex.stl" />;
}

export default StlView;
