// In GridCell.js
import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import './Grid.css';
import { ItemTypes } from './ItemTypes';

function GridCell({ index, component, onDrop }) {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.COMPONENT,
    drop: (item) => {
      onDrop(index, item);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }), [index, onDrop]);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.COMPONENT,
    item: { id: component?.id, name: component?.name, currentIndex: index },
    canDrag: !!component,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }), [component, index]);

  return (
    <div
      ref={drop}
      className="grid-cell"
      style={{
        backgroundColor: isOver ? '#f0f0f0' : '#e0e0e0',
      }}
    >
      {component && (
        <div
          ref={drag}
          className="dropped-content"
          style={{ opacity: isDragging ? 0.5 : 1 }}
        >
          {component.name}
        </div>
      )}
    </div>
  );
}

export default GridCell;