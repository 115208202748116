// Palette.js
import React from 'react';
import { useDrag } from 'react-dnd';
import './Palette.css';
import { ItemTypes } from './ItemTypes';

function PaletteItem({ name }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.COMPONENT,
    item: { name }, // Only pass the name; ID is generated later
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      className="palette-item"
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      {name}
    </div>
  );
}

function Palette() {
  return (
    <div className="palette">
      <PaletteItem name="Component 1" />
      <PaletteItem name="Component 2" />
    </div>
  );
}

export default Palette;
export { ItemTypes };