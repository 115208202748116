// In Grid.js
import React, { useState, useEffect } from 'react';
import './Grid.css';
import GridCell from './GridCell';

let idCounter = 0;
function generateUniqueId() {
  idCounter += 1;
  return '_' + idCounter;
}

function Grid() {
  const [components, setComponents] = useState(() => Array(9).fill(null)); // For a 3x3 grid

  const handleDrop = (targetIndex, item) => {
    const newComponents = [...components]; // Create a new copy of the components array

    if (!item.id) { // New item from palette
      const newItem = { id: generateUniqueId(), name: item.name };
      newComponents[targetIndex] = newItem;
    } else { // Existing item being moved
      if (item.currentIndex !== undefined && item.currentIndex !== targetIndex) {
        newComponents[item.currentIndex] = null; // Clear old position
      }
      const newItem = { id: item.id, name: item.name };
      newComponents[targetIndex] = newItem;
    }

    setComponents(newComponents);
  };

  return (
    <div className="grid">
      {components.map((component, index) => (
        <GridCell
          key={index}
          index={index}
          component={component}
          onDrop={handleDrop}
        />
      ))}
    </div>
  );
}

export default Grid;