// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2px;
  width: 300px;
  height: 300px;
}

.grid-cell {
  width: 100px; /* Adjust the size as needed */
  height: 100px;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Grid.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qCAAqC;EACrC,QAAQ;EACR,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,YAAY,EAAE,8BAA8B;EAC5C,aAAa;EACb,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".grid {\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  gap: 2px;\n  width: 300px;\n  height: 300px;\n}\n\n.grid-cell {\n  width: 100px; /* Adjust the size as needed */\n  height: 100px;\n  background-color: #e0e0e0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
