import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Grid from './components/Grid';
import Palette from './components/Palette';
import  StlView  from './components/StlView';
import './App.css';

function App() {
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="app">
        <div className="palette">
          <Palette />
        </div>
        <div className="grid-container">
          <Grid />
        </div>
      </div>
      <div className="stl-view">
        < StlView />
      </div>

    </DndProvider>
  );
}

export default App;
